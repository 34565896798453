export enum RoutesName {
  EMPTY = '',
  LOGIN = 'login',

  // Personal Module routes
  PERSONAL = 'personal',
  PROFILE_AND_VISIBILITY = 'profile_and_visibility',
  EMAIL = 'email',
  SAFETY = 'safety',
  ACCOUNT_SETTINGS = 'account_settings',
  PRODUCT = 'product',

  // Organization module routes
  ORGANIZATION = 'organization',
  COMPANY_SETTINGS = 'company_settings',
  USER = 'user',
  GROUP = 'group',

  // Subscription and billing module routes
  SUBSCRIPTION_AND_BILLING = 'subscription_and_billing',
  BILLING_DETAILS = 'billing_details',
  MANAGE_SUBSCRIPTIONS = 'manage_subscriptions',
  DISCOVER_APPLICATIONS = 'discover_applications'
}
