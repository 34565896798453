import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RolesEnum, RoutesName, ToasterTypes } from '@drip-enums';
import { User } from '@drip-models';
import { AuthService, CoreHttpService, ToasterService } from '@drip-services';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { LoginUser } from 'src/app/shared/models/login-user.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends CoreHttpService {

  constructor(
    protected override http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private toasterService: ToasterService,
    private translateService: TranslateService
  ) {
    super(http);
  }

  /**
   * get empty login form
   *
   * @returns FormGroup
   */
  public getLoginForm(): FormGroup {
    return new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required)
    });
  }

  /**
   * Login user if he is admin otherwise display error
   *
   * @param form User
   *
   * @returns void
   */
  public login(form: LoginUser): void {
    this.authService.login(form).then(async response => {
      if (response) {
        this.authService.getRoleFromFirestore(response?.user?.uid).subscribe(async value => {
          if (value.role === RolesEnum.ADMINISTRATOR) {
            await this.authService.getSetToken();
            this.authService.setUser = response.user;
            this.router.navigateByUrl(RoutesName.PROFILE_AND_VISIBILITY);
          } else {
            this.authService.logout();
            this.toasterService.showMessage(this.translateService.instant('messages.notAdmin'), ToasterTypes.SUCCESS);
          }
        });

      }
    }).catch(err => {
      this.toasterService.showMessage(err.toString(), ToasterTypes.SUCCESS);
    });
  }

  //TODO Move to correct service when switch from json-server
  public test(): Observable<any> {
    return this.get('/drip-user/list');
  }

  public getUser(userUid: any): Observable<any> {
    return this.get('/drip-user/get/' + userUid);
  }

  //TODO Move to correct service when switch from json-server
  public test2(): Observable<any> {
    return this.get('groups');
  }

  //TODO Move to correct service when switch from json-server
  public test3(): Observable<any> {
    return this.get('billing');
  }
}
