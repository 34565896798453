import { Injectable } from '@angular/core';
import { StorageKeys } from '@drip-enums';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  /**
   * Append items to parent element stored in local storage
   *
   * @param item any
   * @param appendTo StorageKeys
   *
   * @returns void
   */
  public append(item: any, appendTo: StorageKeys): void {
    const value = localStorage.getItem(appendTo);

    if (value) {
      let data = JSON.parse(value);
      if (!data) {
        data = [];
      }
      if (data.indexOf(item) === -1) {
        data.push(item);
      }
      localStorage.setItem(appendTo, JSON.stringify(data));
    } else {
      const data = [item];
      localStorage.setItem(appendTo, JSON.stringify(data));
    }
  }

  /**
   * Get item from storage and return
   * it parsed, if item exists. Return
   * null otherwise
   *
   * @param item StorageKeys (enum)
   *
   * @returns any
   */
  public get(item: StorageKeys): any {
    const value = localStorage.getItem(item);
    if (value && value!=='undefined') {

      return JSON.parse(value);
    }
    return null;
  }

  /**
   * Store value into item located in local storage
   *
   * @param item StorageKeys
   * @param value any
   *
   * @returns void
   */
  public set(item: StorageKeys, value: any): void {
    localStorage.setItem(item, JSON.stringify(value));
  }

  /**
   * Removes item from storage
   *
   * @param item StorageKeys
   *
   * @returns void
   */
  public remove(item: StorageKeys): void {
    localStorage.removeItem(item);
  }

  /**
   * Returns current value of item
   * and removes it from storage.
   * This method is going to return null
   * if item is not stored in local storage.
   *
   * @param item StorageKeys
   *
   * @returns any
   */
  public getAndRemove(item: StorageKeys): any {
    const currentValue = localStorage.getItem(item);

    if (currentValue) {
      const value = JSON.parse(currentValue);
      localStorage.removeItem(item);
      return value;
    } else {
      return null;
    }
  }

  /**
   * Check if item exists in storage or not.
   *
   * @param item StorageKeys | string
   *
   * @returns boolean
   */
  public checkIfExists(item: StorageKeys | string): boolean {
    return localStorage.getItem(item) ? true : false;
  }

  /**
   * Removes multiple items from storage
   *
   * @param items Array of StorageKeys
   *
   * @returns void
   */
  public removeMultiple(items: Array<StorageKeys>): void {
    for (const item of items) {
      this.remove(item);
    }
  }

  /**
   * Stores multiple items into storage
   *
   * @param items Array<StorageKeys>
   * @param values Array
   *
   * @returns void
   */
  public setMultiple(items: Array<StorageKeys>, values: []): void {
    for (let i = 0; i < items.length; i++) {
      this.set(items[i], values[i]);
    }
  }

  /**
   * Returns array if variable is stored
   * and empty array if variable is
   * not array or not stored.
   *
   * @param item StorageKeys
   *
   * @returns array
   */
  public getArray(item: StorageKeys): Array<any> {

    const value = localStorage.getItem(item);

    if (value) {
      const data = value;
      return data != null ? JSON.parse(value) : [];
    } else {
      return [];
    }
  }

  /**
   * Delete all items from storage
   */
  public clear(): void {
    localStorage.clear();
  }

  /**
   * Allows setting non-StorageKey - value pairs.
   * Suitable for keys that contains variables in name.
   *
   * @param key any
   * @param value any
   *
   * @returns void
   */
  public setDynamicKey(key: any, value: any): void {
    localStorage.setItem(key, value);
  }

  /**
   * Returns value saved with dynamic key.
   * Key may be non-StorageKey value.
   *
   * @param key any
   *
   * @returns any
   */
  public getDynamicKey(key: any): any {
    const value = localStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
    return null;
  }

  /**
   * Removes from local storage, by dynamic key
   *
   * @param key dynamic key
   *
   * @returns void
   */
  public removeDynamicKey(key: any): void {
    if (localStorage.getItem(key)) {
      localStorage.removeItem(key);
    }
  }

}
