import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@drip-services';
import { BehaviorSubject, filter } from 'rxjs';
import { AdminPortalSideMenuService } from 'src/app/core/services/utilities/admin-portal-side-menu.service';

@Component({
  selector: 'drip-admin-side-menu',
  templateUrl: './admin-side-menu.component.html',
  styleUrls: ['./admin-side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminSideMenuComponent implements OnInit {

  //#region Class properties

  public adminSideMenu$ = this.adminPortalSideMenuService.adminSideMenuContent$;
  public url$ = new BehaviorSubject<string>('');

  public expanded$ = new BehaviorSubject<boolean>(false);
  //#endregion

  constructor(
    private adminPortalSideMenuService: AdminPortalSideMenuService,
    private router: Router,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.adminPortalSideMenuService.expanded$.subscribe(value => {
      this.expanded$.next(value);
    });
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(
      (value) => {
        this.url$.next((value as NavigationEnd).url);
      }
    );
  }

  //#region UI events

  /**
   * Navigate to selected page from side menu.
   *
   * @param title
   * @param subtitle
   *
   * @returns void
   */
  public onNavigate(title: string): void {
    this.router.navigateByUrl(`${title}`);
  }
  /**
   * Logout
   *
   * @returns Promise<void>
   */
  public async onLogout(): Promise<void> {
    await this.authService.logout();
  }

  /**
   * Expand and collapse side menu
   *
   * @returns void
   */
  expandSideMenu():void {
    let item = document.getElementById('wrapper');
    if (item) {
      if (!this.adminPortalSideMenuService.expanded$.value) {

        this.adminPortalSideMenuService.expanded$.next(true);
        item.style.width = '200px';
      } else {
        this.adminPortalSideMenuService.expanded$.next(false);
        item.style.width = '56px';
      }
    }
  }

  //#endregion

}
