import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[dripFormField]'
})
export class dripFormFieldDirective {

  @HostBinding('class')
  elementClass = 'drip-form-field';

  constructor() {
  }
}
