import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoaderService } from '@drip-services';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnInit {

  public isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.subscribeToLoadingValue();
  }

  //#region Subscriptions

  /**
   * Subscribe to Show Loader property from loader service,
   * and based on it's value, decide if we need to display the loader or not.
   *
   * @returns void
   */
  private subscribeToLoadingValue(): void {
    this.loaderService.showLoader$
      .subscribe((show) => {
        this.isLoading$.next(show);
        this.cdr.detectChanges();
      });
  }

  //#endregion

}
