import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'drip-button',
  templateUrl: './drip-button.component.html',
  styleUrls: ['./drip-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DripButtonComponent {

  @Input() buttonType: string;
  @Input() disabled: boolean | null | undefined;

}
