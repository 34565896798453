import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { AdminProfileCardComponent } from '../../components/admin-profile-card/admin-profile-card.component';
import { MakeAcronymPipe } from '@drip-pipes';
import { AdminPortalLayoutComponent } from '../../components/layout/admin-portal-layout/admin-portal-layout.component';
import { AdminHeaderComponent } from '../../components/layout/admin-header/admin-header.component';
import { AdminSideMenuComponent } from '../../components/layout/admin-side-menu/admin-side-menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { dripFormFieldDirective } from '../../directives/drip-form-field.directive';
import { SearchFormFieldDirective } from '../../directives/search-form-field.directive';
import { TableComponent } from '../../components/table/table.component';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CardContainerComponent } from '../../components/card-container/card-container.component';
import { DripButtonComponent } from '../../components/drip-button/drip-button.component';
import { MatDialogModule } from '@angular/material/dialog';

const sharedComponents = [
  AdminPortalLayoutComponent,
  AdminHeaderComponent,
  AdminSideMenuComponent,
  dripFormFieldDirective,
  AdminProfileCardComponent,
  SearchFormFieldDirective,
  TableComponent,
  CardContainerComponent,
  DripButtonComponent
];
@NgModule({
  declarations: [
    MakeAcronymPipe,
    ...sharedComponents
  ],
  imports: [
    MaterialModule,
    MatTableModule,
    MatPaginatorModule,
    TranslateModule,
    MatSlideToggleModule,
    CommonModule,
    MatDialogModule
  ],
  exports: [
    MatSlideToggleModule,
    CommonModule,
    MatPaginatorModule,
    RouterModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    MatDialogModule,
    ...sharedComponents
  ]
})
export class SharedModule { }
