<table mat-table [dataSource]="tableData" class="table-drip">

  <ng-container *ngFor="let column of displayedColumns">
    <ng-container [matColumnDef]="column">
      <!-- with [disabled] we'll disable sort for non sortable columns, as well as when we don't have data in our table -->
      <th mat-header-cell *matHeaderCellDef>
        {{ 'table.'+column | translate }}
      </th>
      <td mat-cell *matCellDef="let data">
        <ng-container [ngSwitch]="column">

          <ng-container *ngSwitchDefault>
            {{ data[column] }}
          </ng-container>

          <ng-container *ngSwitchCase="'info'">
            <span class="cursor-pointer">
              {{ 'users.details' | translate }}
            </span>
          </ng-container>

          <ng-container *ngSwitchCase="'user'">
            <drip-admin-profile-card class="card" [isAdmin]="data.isAdmin"
              [title]="(data.firstName[0] | uppercase) +'. '+data.lastName" [subtitle]="data.email"
              size="small"></drip-admin-profile-card>
          </ng-container>

          <ng-container *ngSwitchCase="'name'">
            <drip-admin-profile-card class="card" [noImage]="true" [isAdmin]="data.isAdmin" [title]="data.name"
              [subtitle]="'data.groupname.admin'" size="small"></drip-admin-profile-card>
          </ng-container>

          <ng-container *ngSwitchCase="'edit'">
            <mat-icon class="material-icons-outlined"> {{data.edit? 'check_circle' : 'cancel' }} </mat-icon>
          </ng-container>

          <ng-container *ngSwitchCase="'view'">
            <mat-icon class="material-icons-outlined"> {{data.view? 'check_circle' : 'cancel' }} </mat-icon>
          </ng-container>

          <ng-container *ngSwitchCase="'options'">
            <mat-icon class="cursor-pointer">more_vert</mat-icon>
          </ng-container>

          <ng-container *ngSwitchCase="'action'">
            <span class="clickable" (click)="onUserClick(data.uid)"> View Details</span>
          </ng-container>

          <ng-container *ngSwitchCase="'empty'">
            <mat-icon class="cursor-pointer">more_vert</mat-icon>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<!-- TODO: FIX WHEN PAGINATION IS CONSISTENT! -->

<div *ngIf="!tableData.paginator && pagination">
  <div class="pagination-wrapper" [ngClass]="{'extra-border':extraBorder}">
    <mat-icon (click)="onPreviousPage()"
      [ngClass]="{'arrows':pagination.currentPage>1,'disable-arrows':pagination.currentPage===1}">
      keyboard_backspace </mat-icon>
    <span class="page-numbers">{{ pagination.currentPage }} {{ 'table.of' | translate }} {{ pagination.totalPages
      }}</span>
    <mat-icon (click)="onNextPage()"
      [ngClass]="{'arrows':pagination.totalPages!==pagination.currentPage,'disable-arrows':pagination.totalPages===pagination.currentPage}"
      class="arrow-front">
      keyboard_backspace </mat-icon>
  </div>
</div>

<div *ngIf="tableData.paginator">
  <div class="pagination-wrapper" [ngClass]="{'extra-border':extraBorder}">
    <mat-icon (click)="onPreviousPage()"
      [ngClass]="{'arrows':paginator.hasPreviousPage(),'disable-arrows':!paginator.hasPreviousPage()}">
      keyboard_backspace </mat-icon>
    <span class="page-numbers">{{ paginator.pageIndex+1 }} {{ 'table.of' | translate }} {{ pagination.lastPage }}</span>
    <mat-icon (click)="onNextPage()"
      [ngClass]="{'arrows':paginator.hasNextPage() ,'disable-arrows':!paginator.hasNextPage()}" class="arrow-front">
      keyboard_backspace </mat-icon>
  </div>
</div>

<mat-paginator #paginator style="display: none;">
</mat-paginator>