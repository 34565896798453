import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { AdminPortalSideMenuService } from 'src/app/core/services/utilities/admin-portal-side-menu.service';

@Component({
  selector: 'drip-admin-portal-layout',
  templateUrl: './admin-portal-layout.component.html',
  styleUrls: ['./admin-portal-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminPortalLayoutComponent implements AfterViewInit {

  constructor(private sideMenuService: AdminPortalSideMenuService
  ) {
  }
  ngAfterViewInit(): void {
    this.subscribeToExpand();
  }

  /**
   * Fix sizes of components when side-bar expands and collapses
   *
   * @returns void
   */
  subscribeToExpand():void {
    let appContent = document.getElementById('app-content');
    let matContent = document.getElementById('mat-content');
    this.sideMenuService.expanded$.subscribe(value => {
      if (appContent && matContent) {
        if (value) {
          appContent.style.width = 'calc(100vw - 200px)';
          matContent.style.marginLeft = '200px';
        } else {
          appContent.style.width = 'calc(100vw - 56px)';
          matContent.style.marginLeft = '56px';
        }
      }
    });
  }

}
