import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'makeAcronym'
})
export class MakeAcronymPipe implements PipeTransform {

  transform(value: string | null, ...args: unknown[]): unknown {

    let result = value ? value.match(/\b(\w)/g)?.join('') : '';

    if (result!.length > 2) {
      result = result!.slice(0, 2);
    }
    return result;
  }

}
