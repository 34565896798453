import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'drip-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements OnChanges {

  @Output() userClickEvent = new EventEmitter<string>();

  @Output() paginationChange = new EventEmitter<number>();

  @ViewChild('paginator') paginator: MatPaginator;

  @Input() public tableData: any;
  @Input() public pagination: any;
  @Input() public displayedColumns: any[];
  @Input() public extraBorder: boolean;

  ngOnChanges() {
    if (this.tableData.data) {
      setTimeout(() => {
        this.paginator.pageSize = 5; // TODO: changable?
        this.paginator.length = this.tableData.data.length;
        this.tableData.paginator = this.paginator;
      });
    }
  }

  onNextPage() {
    if (this.tableData.paginator) {
      this.paginator.nextPage();
    } else {
      if ((this.pagination.currentPage + 1) <= this.pagination.totalPages) {
        this.paginationChange.emit(this.pagination.currentPage + 1);
      }
    }
  }

  onPreviousPage() {
    if (this.tableData.paginator) {
      this.paginator.previousPage();
    } else {
      if (this.pagination.currentPage > 1) {
        this.paginationChange.emit(this.pagination.currentPage - 1);
      }
    }
  }

  /**
   * Emit value when ser Clicks on view Details
   *
   * @param uid string
   *
   * @returns void
   */
  public onUserClick(uid: string): void {
    this.userClickEvent.emit(uid);
  }

}
