// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  development: true,

  dripBackendConfig: {
    backendUrl: 'https://api.qa.drip-log.com/ap/api/'
  },
  firebaseConfig: {
    apiKey: "AIzaSyBhcpLPNILOUDLulIbzCJOZfFY3RsAfjyg",
    authDomain: "dashboard-test-ba888.firebaseapp.com",
    databaseURL: "https://dashboard-test-ba888.firebaseio.com",
    projectId: "dashboard-test-ba888",
    storageBucket: "dashboard-test-ba888.appspot.com",
    messagingSenderId: "801217274195",
    appId: "1:801217274195:web:0db78b6f568b396e45c1a6"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
