<div id="wrapper" *ngIf="adminSideMenu$ | async; let adminSideMenu" class="admin-menu-wrapper">
  <div class="title-row">
    <i class="fas fa-bars side-menu-icon" (click)="expandSideMenu()"></i>
<span class="expanded-icon-title" *ngIf="(expanded$ | async)">{{'sideMenu.collapse' | translate}}</span>
  </div>
  <!-- Main Menu content -->
  <div *ngFor="let menuContent of adminSideMenu" (click)="onNavigate(menuContent.title)">

    <div class="title-row" [ngClass]="{'current-route':(url$ | async)!!.includes(menuContent.title)}">
      <i
        class="{{menuContent.icon}} side-menu-icon"></i>
      <span class="expanded-icon-title" *ngIf="(expanded$ | async)">{{'modules.'+menuContent.title | translate}}</span>
    </div>
  </div>

  <!-- Subtitles from menu -->

</div>
<!-- <div class="logout-row">
  <button (click)="onLogout()"class="logout-button" mat-raised-button>{{'buttons.logout' | translate}}</button>
</div> -->