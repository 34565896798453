<mat-card class="profile-card-wrapper">

  <!-- Left card content -->
  <div *ngIf="!noImage" class="profile-card-left avatar" [ngClass]="size">
    <img *ngIf="imageUrl; else noImageAvatar" [src]="imageUrl" class="profile-img " [ngClass]="size">
  </div>

  <!-- Right card content -->
  <mat-card-header class="profile-card-right">
    <mat-card-title class="profile-title padding-content header-text" [ngClass]="'card-title-'+size">
      <div>{{title}}</div>
      <div *ngIf="isAdmin" class="admin">{{ 'users.isAdmin' | translate }}</div>
    </mat-card-title>
    <mat-card-subtitle class="profile-subtitle padding-content label-text" [ngClass]="'card-subtitle-'+size">
      <div>{{subtitle ? subtitle : '-'}}</div>
    </mat-card-subtitle>
  </mat-card-header>
</mat-card>

<!-- If no image provided apply template instead img tag -->
<ng-template #noImageAvatar>
  <div class="profile-card-left avatar" class="noImageAvatar" [ngClass]="'avatar-'+size">
    {{ title | makeAcronym}}
  </div>
</ng-template>
