import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { RoutesName } from '@drip-enums';

const routes: Routes = [
  {
    path: RoutesName.EMPTY,
    redirectTo: RoutesName.PROFILE_AND_VISIBILITY,
    pathMatch: 'full'
  },
  {
    path: RoutesName.LOGIN,
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: RoutesName.PROFILE_AND_VISIBILITY,
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/personal/personal.module').then(m => m.PersonalModule)
  },
  {
    path: RoutesName.ORGANIZATION,
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/organization/organization.module').then(m => m.OrganizationModule)
  },
  {
    path: RoutesName.SUBSCRIPTION_AND_BILLING,
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/subscription-and-billing/subscription-and-billing.module').then(m => m.SubscriptionAndBillingModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
