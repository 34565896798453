<mat-card>
  <mat-card-title>
    {{title | uppercase}}
  </mat-card-title>
  <mat-card-content>
    <ng-content></ng-content>
  </mat-card-content>
  <mat-card-actions>
    <ng-content select="[cardActions]"></ng-content>
  </mat-card-actions>
</mat-card>