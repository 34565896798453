import { Injectable } from '@angular/core';
import { Language } from '@drip-models';
import { TranslateService } from '@ngx-translate/core';
import { StorageKeys } from '@drip-enums';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  public allLanguages: Language[];
  private readonly defaultLanguage = 'en';

  constructor(
    private translateService: TranslateService,
    private storageService: StorageService
  ) { }

  /**
   * Get previously used language saved in storage.
   * If no saved language, use English as a default.
   *
   * @returns void
   */
  public initDefaultLanguage(): void {
    const savedLangCode = this.storageService.get(StorageKeys.language);
    if (savedLangCode) {
      this.setLanguage(savedLangCode);
    } else {
      this.setLanguage(this.defaultLanguage);
    }
  }

  /**
   * Set new language by param 'lang', which is
   * two-characters long language code (en, de, fr...).
   *
   * @param lang string
   *
   * @returns void
   */
  public setLanguage(lang: string = this.defaultLanguage): void {
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
    this.storageService.set(StorageKeys.language, lang);
    this.init();
    const language = this.allLanguages.find(x => x.code === lang);
    if (language) {
      language.selected = true;
    }
  }

  /**
   * Get the list of all Languages available.
   *
   * @returns Language[]
   */
  public getAllLanguages(): Language[] {
    if (!this.allLanguages) {
      this.init();
    }
    return this.allLanguages;
  }

  /**
   * Returns currently selected language.
   *
   * @returns Language
   */
  public getCurrentLanguage(): Language {
    const current = this.allLanguages.find(x => x.selected);
    return current ? current : this.allLanguages[0];
  }

  /**
   * Create and return simple form for language selection with current language value
   *
   * @returns FormGroup
   */
  public getLanguageForm(): FormGroup {
    return new FormGroup({
      selectedLanguage: new FormControl(this.getCurrentLanguage(), Validators.required)
    });
  }

  /**
   * Initialize list of languages available.
   *
   * @returns void
   */
  private init(): void {
    this.allLanguages = [
      { name: 'English', code: 'en', selected: false },
      { name: 'Deutsch', code: 'de', selected: false },
      { name: 'Polski', code: 'pl', selected: false },
      { name: 'български', code: 'bg', selected: false },
      { name: 'Română', code: 'ro', selected: false },
      { name: 'Hrvatski', code: 'hr', selected: false },
      { name: 'Slovenský', code: 'sk', selected: false },
      { name: 'Český', code: 'cs', selected: false },
      { name: 'Afrikaans', code: 'af', selected: false },
      { name: 'Breton', code: 'br', selected: false },
      { name: 'Español', code: 'es', selected: false },
      { name: 'Magyar', code: 'hu', selected: false },
      { name: 'Italiana', code: 'it', selected: false },
      { name: 'Русский', code: 'ru', selected: false },
      { name: 'Srpski', code: 'sr', selected: false },
      { name: 'Türk', code: 'tr', selected: false },
      { name: 'Slovenščina', code: 'sl', selected: false }
    ];
  }
}
