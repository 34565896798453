import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/modules/shared/shared.module';
import { LoaderComponent } from '@drip-components';
import { LoaderInterceptor, AuthInterceptor } from '@drip-interceptors';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/compat/auth';
import { EnvironmentService } from './core/services/utilities/environment.service';
import { firstValueFrom } from 'rxjs';
import { ConfirmActionComponent } from './shared/components/confirm-action/confirm-action.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function loadEnvironment(environmentService: EnvironmentService): () => Promise<void> {
  return () => {
    return firstValueFrom(environmentService.loadEnvironment());
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    ConfirmActionComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    SharedModule,
    AngularFireModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: loadEnvironment,
      multi: true,
      deps: [EnvironmentService]
    },
    AngularFireAuth,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    EnvironmentService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
