import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'drip-confirm-action',
  templateUrl: './confirm-action.component.html',
  styleUrls: ['./confirm-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmActionComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string },
  ) { }

}