import { ChangeDetectionStrategy, Component } from '@angular/core';
@Component({
  selector: 'drip-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminHeaderComponent {

}
