<div>
  <drip-admin-header></drip-admin-header>
  <mat-sidenav-container>
    <!-- sidenav -->
    <mat-sidenav mode="side" opened>
      <!-- side nav component for navigation -->
      <drip-admin-side-menu></drip-admin-side-menu>
    </mat-sidenav>

    <!-- application core -->
    <mat-sidenav-content id="mat-content">

      <!-- application content -->
      <div id="app-content">
        <router-outlet></router-outlet>
      </div>

    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
