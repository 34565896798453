import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'drip-card-container',
  templateUrl: './card-container.component.html',
  styleUrls: ['./card-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardContainerComponent {

  @Input() title: string;
  @Input() buttonText: string;

}
