import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AboutYouForm, ContactForm, ProfileField } from '@drip-models';
import { Contact, AboutYou } from 'src/app/shared/models/profile-and-visibility/user-profile.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileFormService {

  constructor() { }

  /**
   * Create form for AboutYou
   *
   * @param profileFields AboutYou
   *
   * @returns FormGroup<AboutYouForm>
   */
  public createAboutYouForm(profileFields: AboutYou): FormGroup<AboutYouForm> {
    let form: AboutYouForm = {} as AboutYouForm;

    Object.entries(profileFields).forEach(([key, value]) => {
      form[<keyof AboutYou>key] = new FormGroup<ProfileField<string | null>>({
        value: new FormControl<string | null>(value.value, key !== 'name' ? [] : Validators.required),
        visibility: new FormControl<string | null>(value.visibility)
      });
    });
    return new FormGroup<AboutYouForm>(form);
  }

  /**
   * Create form for Contacts
   *
   * @param profileFields Contact
   *
   * @returns FormGroup<ContactForm>
   */
  public createContactForm(profileFields: Contact): FormGroup<ContactForm> {
    let form: ContactForm = {} as ContactForm;

    Object.entries(profileFields).forEach(([key, value]) => {
      form[<keyof Contact>key] = new FormGroup<ProfileField<string | null>>({
        value: new FormControl<string | null>(value.value, key !== 'email' ? [] : Validators.required),
        visibility: new FormControl<string | null>(value.visibility)
      });
    });
    return new FormGroup<ContactForm>(form);
  }
}
