
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  constructor(private http: HttpClient) { }
  public loadEnvironment(): Observable<void> {
    return new Observable<void>((observer) => {
      this.http.get('assets/env/env.json')
        .subscribe(
          (data: any) => {
            if (data.PARAM_API_URL.length > 0) {
              environment.dripBackendConfig.backendUrl = data.PARAM_API_URL;
            }
            observer.next();
            observer.complete();
          }
        );
    });
  }
}