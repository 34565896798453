import { Injectable } from '@angular/core';
import { RoutesName, StorageKeys } from '@drip-enums';
import { User } from '@drip-models';
import { StorageService } from '../utilities';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Observable, filter, first, map } from 'rxjs';
import { LoginUser } from 'src/app/shared/models/login-user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private storageService: StorageService,
    private angularFireAuth: AngularFireAuth,
    private router: Router,
    private afs: AngularFirestore
  ) { }

  private myAccessToken: string | undefined;

  /**
  * Get access token
  */
  public get accessToken(): string | undefined {
    if (!this.myAccessToken) {
      this.myAccessToken = this.storageService.get(StorageKeys.accessToken);
    }

    return this.myAccessToken;
  }

  public async login(form: LoginUser): Promise<firebase.default.auth.UserCredential> {
    return await this.angularFireAuth.signInWithEmailAndPassword(form.email, form.password);;
  }

  /**
   * Logout from app and logout user from firestore
   * Redirect to login page
   *
   * @returns Promise<void>
   */
  public async logout(): Promise<void> {
    this.setUser = null;
    this.storageService.remove(StorageKeys.accessToken);
    this.myAccessToken = undefined;
    this.router.navigateByUrl(RoutesName.LOGIN);
    await this.angularFireAuth.signOut();
  }

  private user: firebase.default.User | null;

  public get getUser() {
    return this.user;
  }

  public set setUser(user: firebase.default.User | null) {
    this.user = user;
  }

  /**
   * Get end set auth token
   * If expired, refresh token with a new one
   *
   * @return Promise<void>
   */
  public async getSetToken(): Promise<void> {
    await this.angularFireAuth.currentUser.then(async (user) => {
      await user?.getIdToken().then(value => {
        this.myAccessToken = value;
        this.storageService.set(StorageKeys.accessToken, value);
      });
    });
  }

  /**
   * Check if user is logged in
   * If so , check the token
   * Else logout
   *
   * @returns void
   */
  public async checkUserToken() {
    await this.angularFireAuth.onAuthStateChanged(async (user) => {
      if (user) {
        await this.getSetToken();
      } else {
        await this.logout();
      }
    });
  }

  /**
   * Get current user from firestore database
   *
   * @param uid string
   * @returns Observable<User>
   */
  public getRoleFromFirestore(uid: string | undefined): Observable<User> {
    return this.afs.collection(`users`,
      (ref) => ref
        .where('uid', '==', uid))
      .snapshotChanges()
      .pipe(
        filter(x => x.length != 0),
        filter(x => x[0].payload.doc.metadata.fromCache === false),
        first(),
        map(x => (x[0].payload.doc.data() as any)));
  }

}
