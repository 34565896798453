import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'drip-admin-profile-card',
  templateUrl: './admin-profile-card.component.html',
  styleUrls: ['./admin-profile-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminProfileCardComponent {

  //#region Angular Inputs

  @Input() title: string;
  @Input() subtitle: string;
  @Input() imageUrl: string;
  @Input() isAdmin: boolean;
  @Input() noImage: boolean = false;

  // determinate card size (avatar, title, subtitle)
  @Input() size: 'small' | 'medium' | 'large';

  //#endregion

}
