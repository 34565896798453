import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[dripSearchFormField]'
})
export class SearchFormFieldDirective {

  @HostBinding('class')
  elementClass = 'search-input-field';

  constructor() {
  }

}
