import { Injectable } from '@angular/core';
import { RoutesName } from '@drip-enums';
import { AdminSideMenuContent } from '@drip-models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminPortalSideMenuService {

  public adminSideMenuContent$ = new BehaviorSubject<AdminSideMenuContent[]>([]);
  public expanded$ = new BehaviorSubject<boolean>(false);

  constructor() {
    this.initSideMenuContent();
  }

  /**
   * Set initial content for application side menu.
   *
   * @returns void
   */
  private initSideMenuContent(): void {

    const adminSideMenu: AdminSideMenuContent[] = [
      {
        title: RoutesName.PROFILE_AND_VISIBILITY,
        icon: 'fas fa-user',
      },
      {
        title: RoutesName.ORGANIZATION,
        icon: 'fas fa-building',
      },
      {
        title: RoutesName.SUBSCRIPTION_AND_BILLING,
        icon: 'fas fa-flag'
      }
    ];

    this.adminSideMenuContent$.next(adminSideMenu);
  }
}
