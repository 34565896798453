<mat-toolbar class="admin-header">
  <!-- Header left side -->
  <div class="toolbar-left">
    <img src="assets/images/login-logo.svg" alt="logo" class="logo" />
  </div>

  <!-- Header right side -->
  <div class="toolbar-right">
    <img src="assets/images/login-logo.svg" alt="user photo" class="user-avatar-photo" />
    <span class="user-name">John Doe</span>
  </div>
</mat-toolbar>
