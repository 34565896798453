import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  public showLoader$ = new BehaviorSubject<boolean>(false);
  private loaderCounter: number = 0;

  constructor() {
  }

  public showLoader(): void {
    this.loaderCounter++;
    this.showLoader$.next(true);
  }

  public hideLoader(): void {
    if (this.loaderCounter > 0) {
      this.loaderCounter--;
    }
    if (this.loaderCounter <= 0) {
      this.showLoader$.next(false);
    }
  }
}
