import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './core/services/auth';
import { LanguageService } from './core/services/utilities';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  // todo: subscribe for user
  public isUserAuth$ = new BehaviorSubject<boolean>(true);

  constructor(
    private languageService: LanguageService,
    private authService: AuthService
  ) {
    this.languageService.initDefaultLanguage();
  }

  async ngOnInit(): Promise<void> {
    await this.authService.checkUserToken();
  }

}
